<template>
	<div :class="{is_zooming : getZoomingAnimationOnTreeActive}">
		<div class="tree">
			<div id="graph"></div>
			<div class="graph-zoom">
				<div @click.prevent="goToList()" class="btn-primary btn-primary--outline list-view">
					<i class="fa-regular fa-list"></i>
					List view
				</div>
				<div class="range">
				<button id="minus" class="circle" data-quantity="minus" v-on:click="zoomOut()">
					<i class="fa fa-minus"></i>
				</button>
				<input id="graphZoom" name="graphZoom" type="range" min="0" max="100" v-model="graphZoom" @input="changeRange" />
				<button id="plus" class="circle" data-quantity="plus" v-on:click="zoomIn()">
					<i class="fa fa-plus"></i>
				</button>
				</div>
				<div class="zoom-info">
					<output for="graphZoom" id="output"><span>Zoom</span> <span>{{ graphZoom + "%" }}</span></output>
					<button class="reset-zoom" v-on:click="resetZoom()"><i class="fa-solid fa-rotate-right"></i>Reset</button>
				</div>
			</div>
			<!-- end zoom -->
		</div>
		<!-- end tree -->
	</div>
</template>

<script>
import {mapGetters} from "vuex"
import ForceGraph from "force-graph"
import * as d3 from "d3"


let main = null
let sidebarWrapper = null
let nodeForm = null
let nodeOverview = null
let range = null

//1% of range
let min = 0.3
let max = 30
let step = (max - min) / 100

export default {
	data() {
		return {
			graphZoom: 8,
			Graph: ForceGraph(),
		}
	},
	computed: {
		currentNode() {
			return this.getCurrentNode;
		},
		getRootNode() {
			return this.rootNode;
		},
		...mapGetters("views", ["getSidebarOpenState"]),
		...mapGetters("client", ['getContentTreeWasResized', "getClientContentTree", "getZoomingAnimationOnTreeActive",  "getOldNodeId", "getActiveNode", "getContentTreeFilters", "getCurrentNode", "getClientData", "getHighlightedNode", "getListView", 'getZoomToFitContentTree', 'getClientSlug']),
	},
	watch: {
		getActiveNode(id){
			if(id){
				this.selectNode(id);
			}
		},
		async getZoomToFitContentTree(){
			try{
				console.log('getZoomToFitContentTree');
				this.$store.commit('client/setZoomingAnimationOnTreeActive', true);

				// If the no node in the tree is selected
				if (!this.getClientContentTree.nodeIds.find((nodeId) => nodeId === this.getCurrentNode.id)) {
						// Fit to screen
						this.centerOnNode(this.getClientContentTree.rootNodeId, 'REMOVE');
				} else {
					let currentNodeLevel = this.getCurrentNode.level
					if (currentNodeLevel > 1) {
						this.Graph.zoom(20, 2000)
					} else {
						this.Graph.zoom(12, 2000)
					}
				}
			}catch(error){
				console.log('getZoomToFitContentTree catch error', error);
			}
		}
	},
	components: {},
	props: ["clientSlug"],
	methods: {
		goToList(){
			this.$store.commit('views/closeSidebar');
			console.log('closing contenttree in sidebar')
			this.$store.commit('client/setHidePageLoader', false);
			this.$store.commit('client/setListView', true)
		},
		zoomAnimDone(){
			setTimeout(async () => {
				if (this.getCurrentNode.level == 0 && this.getOldNodeId) {
					await this.$store.dispatch('client/updateNodeActionRecord', {nodeId: this.getOldNodeId, action: 'CLOSE'});
				}
				this.$store.commit('client/setZoomingAnimationOnTreeActive', false);
			}, 4500);
		},
		async enterNode(nodeId){
			setTimeout(async () => {
				//this check is so the data stays in the store when the content tree is resized.
				if(this.getContentTreeWasResized === false){
					await this.$store.dispatch("client/selectNode", nodeId);
					this.$store.commit('client/setRemoveRowLoader', true);
					this.closeAddNode();
				}
				if(this.getContentTreeWasResized){
					if(document.querySelector('.overview-open .edit')){
						document.querySelector('.overview-open .edit').click();
						this.$store.commit('client/setContentTreeWasResized', false);
						const url = new URL(window.location.href);
						if(url.searchParams.get('editor-open') === 'true'){
							this.$store.commit('client/setShowEditor', true);
						}
                	}
				}
			}, 3000);
		},
		waitForNodes() {
			return new Promise((resolve) => {
				const checkNodes = setInterval(() => {
					if (this.Graph.graphData().nodes.length > 0) {
						clearInterval(checkNodes);
						resolve();
					}
				}, 100); // Check every 100ms
			});
    }	,
		async fitToScreen() {
			this.Graph.zoomToFit(2000, 20)
		},
    	closeAddNode() {
			if (nodeForm.classList.contains("form-open")) {
				main.classList.remove("node-form-open")
				main.classList.add("node-form-closed")
				nodeForm.classList.remove("form-open")
				nodeForm.classList.add("form-closed")
			}
		},
		async centerOnNode(nodeId, action = false) {
			this.$store.commit('client/setZoomingAnimationOnTreeActive', true);
			try{
				await this.waitForNodes();
				let forceGraphNodes = this.Graph.graphData().nodes;
				let nodeToFocus = forceGraphNodes.find((node) => nodeId == node.id);
				if (nodeToFocus.level !== 0) {
					this.openNodeOverview()
				}
				if(this.getListView === false){
					if(nodeToFocus){
						if(action === 'REMOVE'){
							if(this.getClientContentTree.nodeIds.length > 50){
								this.Graph.zoom(1, 2000)	
							}else if(this.getClientContentTree.nodeIds.length > 20){
								this.Graph.zoom(2, 2000)	
							}else{
								this.Graph.zoom(3, 2000)
							}
							setTimeout(() => {
								this.Graph.centerAt(nodeToFocus.x, nodeToFocus.y, 2000);
							}, 2000);
							this.zoomAnimDone();
						}else{
							this.Graph.centerAt(nodeToFocus.x, nodeToFocus.y, 2000);
							setTimeout(() => {
								if (nodeToFocus.level == 0) {
									this.Graph.zoom(1, 2000)
								}else if (nodeToFocus.level == 1) {
									this.Graph.zoom(18, 2000)
								} else if (nodeToFocus.level == 2) {
									this.Graph.zoom(22, 2000)
								} else if (nodeToFocus.level >= 3) {
									this.Graph.zoom(26, 2000)
								} else {
									this.Graph.zoom(12, 2000)
								}
							}, 2000);
							this.zoomAnimDone();
						}
					}
				}
				if(action === 'ENTER'){
					await this.enterNode(nodeId);
				}
			}catch(error){
				console.log('centerOnNode catch error', error);
			}
		},
		async selectNode(nodeId) {
			try{
				this.centerOnNode(nodeId, 'ENTER');
				this.$emit("selectNode")
				this.closeSidebar()
				this.openNodeOverview()
			}catch(error){
				console.log('select node catch error', error);
			}
		},
		highlightNode(nodeId) {
			this.$emit("highlightNode")
			this.$store.dispatch("client/highlightNode", nodeId)
		},
		clearHighlightNode() {
			this.$emit("clearHighlightNode")
			this.$store.dispatch("client/clearHighlightNode")
		},
		openAddNode() {
			if (nodeForm.classList.contains("form-closed")) {
				main.classList.remove("node-form-closed")
				main.classList.add("node-form-open")
				nodeForm.classList.remove("form-closed")
				nodeForm.classList.add("form-open")
			}
			this.closeNodeOverview()
		},
		closeSidebar() {
			this.$store.dispatch("views/closeSidebar")
			if (!this.getSidebarOpenState) {
				sidebarWrapper.classList.remove("open")
				sidebarWrapper.classList.add("closed")
			}
		},
		closeNodeOverview() {
			if (nodeOverview.classList.contains("overview-open")) {
				main.classList.remove("overview-open")
				main.classList.add("overview-closed")
				nodeOverview.classList.remove("overview-open")
				nodeOverview.classList.remove("overview-closed")
			}
		},
		openNodeOverview() {
			if (nodeOverview.classList.contains("overview-closed")) {
				main.classList.add("overview-open")
				main.classList.remove("overview-closed")
				nodeOverview.classList.add("overview-open")
				nodeOverview.classList.add("overview-closed")
			}
		},
		zoomIn() {
			const currentValue = Number(range.value)
			if (currentValue >= 0 && currentValue != 100) {
				range.value = currentValue + 1
				this.graphZoom = currentValue + 1
				const zoomLevel = Number(this.graphZoom * step + min)
				this.Graph.zoom(zoomLevel, 100)
			}
		},
		zoomOut() {
			console.log('zoom out');
			const currentValue = Number(range.value)
			if (currentValue <= 100 && currentValue != 0) {
				range.value = currentValue - 1
				this.graphZoom = currentValue - 1
				const zoomLevel = Number(this.graphZoom * step + min)
				this.Graph.zoom(zoomLevel, 100)
			}
		},
		resetZoom() {
			this.Graph.zoomToFit(500, 20);
		},
		changeRange() {
			const currentValue = Number(range.value)
			this.graphZoom = currentValue
			const zoomLevel = Number(this.graphZoom * step + min)
			this.Graph.zoom(zoomLevel, 100)
		},
		numberWithCommas(x) {
			console.log('===== NUMBER WITH COMMAS ====', [x, typeof x]);
			if(x){
				return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			}
		},
        /**
         * =================================================================================================
         * This is the renderContentTree method
         * It has everything to do with how the content tree is rendered on each frame
         * =================================================================================================
         */
        async renderContentTree() {

			if(this.getListView === false){
				document.getElementById("graph").style.display = ''
			}

			//console.log("rendering tree")
			let contentTree = JSON.parse(JSON.stringify(this.getClientContentTree))
			
			// let contentTree = exampleContentTree;
			let NODE_REL_SIZE = 2
			let treeNodes = contentTree.nodes.map(node => ({
				id: node.id, 
				accumulativeReach: node.accumulativeReach, 
				reach: node.reach, 
				name: node.name, 
				title: node.title, 
				status: node.status,
				parent: node.parent,
				level: node.level,
				author: node.author,
				created: node.created
			}));

			let treeLinks = contentTree.links

			console.log('contentTree:', contentTree);
			
			const treeGraphData = {
				nodes: treeNodes,
				links: treeLinks,
			}

			//set circle sizes
			let circleRadius = 8
			let rootRadius = 22
			let level1Radius = 14.36
			let level2Radius = 8.25
			let level3Radius = 4.74

			function wrapText(context, text, x, y, maxWidth, lineHeight) {
				var words = text.split(" ")
				var line = ""
				for (var n = 0; n < words.length; n++) {
					var testLine = line + words[n] + " "
					var metrics = context.measureText(testLine)
					var testWidth = metrics.width
					if (testWidth > maxWidth) {
						context.fillText(line, x, y)
						line = words[n] + " "
						y += lineHeight
					} else {
						line = testLine
					}
				}
				context.fillText(line, x, y)
			}
			function inverseProportion(base, scale) {
				let main = base - scale / base

				if (main <= 1.2) {
					return 1.2 - scale / 100
				} else {
					return main
				}
			}

			function numberWithCommas(x) {
				if(x){
					return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
				}else{
					return `${x}`;
				}
			}

			//generate the graph
			this.Graph(document.getElementById("graph"))
				.graphData(treeGraphData)
				.nodeId("id")
				.nodeVal(3)
				.nodeLabel("label")
				.nodeAutoColorBy("group")
				.linkSource("source")
				.linkTarget("target")
				.zoom(Number(step * 8 + min))
				.minZoom(min)
				.maxZoom(max)
				.linkWidth(2)
				.nodeAutoColorBy("group")
				//.enablePointerInteraction(true)
				// .enableNodeDrag(false)
				.nodeRelSize(NODE_REL_SIZE)
				.d3Force(
					"collision",
					d3.forceCollide((node) => Math.sqrt(500 / (node.level + 10)) * NODE_REL_SIZE)
				)
				.d3VelocityDecay(0.1)
				.autoPauseRedraw(false)
				.d3AlphaDecay(0.02)
				.d3Force("center", null)
				.d3Force("charge", d3.forceManyBody().strength(-20))
				.d3Force(
					"link",
					d3.forceLink().distance(function (link) {
						// console.log(link.target.level)
						if (link.source.level === 0) {
							return 60
						} else if (link.source.level === 1) {
							return 40
						} else if (link.source.level === 2) {
							return 20
						} else if (link.source.level >= 3) {
							return 15
						}
					})
				)
				.onZoom((k) => {
					//update the zoom range slider value
					const value = Number((k.k - min) / (max - min)) * 100
					this.graphZoom = Math.round(value)
				})
				.onNodeClick((node) => {
					let nodeIsFiltered = this.getContentTreeFilters.status === node.status || this.getContentTreeFilters.author === node.author || this.getContentTreeFilters.created === node.created ? true : false;
					let filtersSelected = Object.keys(this.getContentTreeFilters).length > 0
					// zoom on node
					if (nodeIsFiltered || !filtersSelected) {
						this.selectNode(node.id);
					}
				})
				.onNodeHover((node) => {
					if (node) {
						if (node.id) {
							// console.log(node.id)
							this.highlightNode(node.id)
							// this.Graph.d3ReheatSimulation()
						}
					} else {
						this.clearHighlightNode()
					}
				})
				.nodeCanvasObject((node, ctx, globalScale) => {
					let lineThickness = 1.5
					// const label = node.name
					// console.log(node.fieldGroups)
					let nodeTitle = node.title;
					let words = nodeTitle.split(' ');
					if (words.length > 5) {
						nodeTitle = words.slice(0, 5).join(' ') + '...';
					}
					let nodeId = node.name
					let nodeReachText = node.level <= 1 ? node.accumulativeReach : node.reach;
					const fontSize = 14 / globalScale
					ctx.font = `${fontSize}px Gilroy`
					//const bckgDimensions = [textWidth, fontSize].map((n) => n + fontSize * 5)
					const nodeStatus = node.status.toLowerCase() //status value path from data

					//update the node stroke colour using the node status
					const pink = "255,80,162"
					const blue = "42,187,238"
					const orange = "255,131,68"
					const green = "24,178,140"
					const approvedGreen = "68,255,196"
					const grey = "79,81,112"
					const white = "220,220,241"
					const red = "255,0,0";
					let rgbValue = grey

					switch (nodeStatus) {
						case "title created":
							rgbValue = pink
							break
						case "assigned":
							rgbValue = blue
							break
						case "pending review":
							rgbValue = orange
							break
						case "approved":
							rgbValue = approvedGreen
							break
						case "rejected":
							rgbValue = red
							break
						case "published":
							rgbValue = green
							break
						case "archived":
							rgbValue = grey
							break
					}

					//node filtering
					let strokeColor = `rgba(${rgbValue},1)`
					let textFillStyle = `rgba(255,255,255,1)`;
					let nodeIsFiltered = this.getContentTreeFilters.status === node.status || this.getContentTreeFilters.author === node.author || this.getContentTreeFilters.created === node.created ? true : false;
					let filtersSelected = Object.keys(this.getContentTreeFilters).length > 0
					let nodeIsCurrentNode = node.id === this.getCurrentNode.id
					let nodeIsHighlightedNode = node.id === this.getHighlightedNode.id

					if (!nodeIsFiltered && filtersSelected) {
						// ctx.globalCompositeOperation = "lighter";
						// ctx.globalAlpha = 0.5
						strokeColor = `rgba(${rgbValue}, 0.1)`
						textFillStyle = `rgba(255,255,255, 0.1)`

						if (nodeIsCurrentNode) {
							strokeColor = `rgba(${rgbValue}, 0.3)`
							textFillStyle = `rgba(255,255,255, 0.3)`
						}
					}

					//set node sizes based on level
					if (node.parent === "root") {
						//center root node
						node.fx = node.x
						node.fy = node.y
						circleRadius = rootRadius
						lineThickness = 3.5
						strokeColor = `rgba(${grey}, 1)`
						nodeTitle = this.getClientData.clientName
					} else if (node.level === 1) {
						lineThickness = 3.5
						circleRadius = level1Radius
						strokeColor = `rgba(${grey}, 1)`
					} else if (node.level === 2) {
						lineThickness = 2
						circleRadius = level2Radius
					} else if (node.level > 2) {
						lineThickness = 2
						circleRadius = level3Radius
					}

					let shadowColor = `#0000003b`;
					let shadowBlur = 35
					//let icon = "\uf05a" //info icon
					//let icon = "\uf055" //plus icon

					//current clicked node
					if (nodeIsCurrentNode) {
						shadowColor = strokeColor
						shadowBlur = 35
						this.Graph.centerAt(node.x - 15, node.y, 10)
					}

					//hovered node
					if (nodeIsHighlightedNode || nodeIsCurrentNode) {
						if (node.parent === "root" || node.level === 1 ) {
							shadowColor = `rgba(${white}, 1)`
							shadowBlur = 35
						} else {
							shadowColor = strokeColor
							shadowBlur = 35
						}
					}
					
						ctx.save()
					//apply glow to current node
					ctx.shadowColor = shadowColor
					ctx.shadowBlur = shadowBlur
					//set img background

					// ctx.save();

					if (!nodeIsFiltered && filtersSelected) {
						// Draw the lighter image instead
						ctx.beginPath();
						ctx.arc(node.x, node.y, circleRadius, 0, 2 * Math.PI, false);
						ctx.fillStyle = '#242750'; // Set this to your desired color
						ctx.fill();
					} else {
						ctx.beginPath();
						ctx.arc(node.x, node.y, circleRadius, 0, 2 * Math.PI, false);
						ctx.fillStyle = '#242750'; // Set this to your desired color
						ctx.fill();
					}

					ctx.strokeStyle = strokeColor

					ctx.beginPath()
					ctx.lineWidth = lineThickness;

					if (this.Graph.zoom() >= 7) {
						ctx.lineWidth = lineThickness;
					}else if (this.Graph.zoom() >= 2) {
						ctx.lineWidth = lineThickness;
					} else {
						ctx.lineWidth = lineThickness;
					}

					ctx.arc(node.x, node.y, circleRadius, 0, 2 * Math.PI, false)
					ctx.stroke()



					
					ctx.restore();

					ctx.textAlign = "center"
					ctx.font = `400 ${fontSize}px Sans-Serif`
					ctx.fillStyle = textFillStyle

					if (this.Graph.zoom() >= 3) {
						ctx.fillText(nodeId, node.x, node.y - (circleRadius - inverseProportion(5, globalScale)))
					}

					//set the node title params
					// ctx.save()
					ctx.textAlign = "center"
					ctx.textBaseline = "middle"
					ctx.font = `600 ${fontSize}px Sans-Serif`
					ctx.fillStyle = textFillStyle
					if (node.level <= 1) {
						if(node.level === 1){
							//if is category
							if (this.Graph.zoom() >= 4) {
							if (node.level <= 1) {
								wrapText(ctx, nodeTitle, node.x, node.y - inverseProportion(4.2, globalScale), circleRadius * 2 - 2, inverseProportion(4.2, globalScale))
							}
						}
						if (this.Graph.zoom() >= 7) {
							wrapText(ctx, nodeTitle, node.x, node.y - inverseProportion(4.2, globalScale), circleRadius * 2 - 2, inverseProportion(4.2, globalScale))
						}
						}else{
							//if is root
							if (this.Graph.zoom() >= 4) {
								wrapText(ctx, nodeTitle, node.x, node.y - 2, circleRadius * 2 - 2, 0)
							}
							else {
								wrapText(ctx, nodeTitle, node.x, node.y - inverseProportion(4, globalScale), circleRadius * 2 - 2, inverseProportion(8, globalScale))
							}
						}
					} else {
						//if is normal nodes
						if (this.Graph.zoom() >= 7) {
							if (node.level <= 2) {
								wrapText(ctx, nodeTitle, node.x, node.y - inverseProportion(4.2, globalScale), circleRadius * 2 - 2, inverseProportion(4.2, globalScale))
							}
						}
						if (this.Graph.zoom() >= 20) {
							wrapText(ctx, nodeTitle, node.x, node.y - inverseProportion(4.2, globalScale), circleRadius * 2 - 2, inverseProportion(4.2, globalScale))
						}
					}

					ctx.textAlign = "bottom"
					ctx.font = `400 ${fontSize}px Sans-Serif`
					ctx.fillStyle = textFillStyle
					if (this.Graph.zoom() >= 3) {
						if (node.level <= 1) {
							ctx.fillText(numberWithCommas(nodeReachText), node.x, node.y + (circleRadius - inverseProportion(5, globalScale)))
						}
					}
					if (this.Graph.zoom() >= 10) {
						if (node.level <= 2) {
							ctx.fillText(numberWithCommas(nodeReachText), node.x, node.y + (circleRadius - inverseProportion(5, globalScale)))
						}
					}

					if (this.Graph.zoom() >= 12) {
						ctx.fillText(numberWithCommas(nodeReachText), node.x, node.y + (circleRadius - inverseProportion(5, globalScale)))
					}
				
				})
				.linkCanvasObject((link, ctx) => {
					const start = link.source
					const end = link.target
					// const l = Math.sqrt(Math.pow(end.x - start.x, 2) + Math.pow(end.y - start.y, 2)) // line length
					// const a = Math.atan2(end.y - start.y, end.x - start.x) // line angle
					// const angleOffset = (90 * Math.PI) / 180
					//ctx.save()
					if(link.sibling){
					    ctx.save()
						ctx.setLineDash([2, 2]);
						ctx.lineWidth = 0.3;
						ctx.strokeStyle = 'rgb(255 255 255 / 33%)';
						ctx.beginPath();
						ctx.moveTo(start.x, start.y);
						ctx.lineTo(end.x, end.y);
						ctx.stroke();
					  ctx.restore()
					}else{
						ctx.save()
						ctx.lineWidth = 3;
						ctx.strokeStyle = '#3E4264';
						ctx.beginPath();
						ctx.moveTo(start.x, start.y);
						ctx.lineTo(end.x, end.y);
						ctx.stroke();
					  	ctx.restore()
					}
					
				})
				.nodePointerAreaPaint((node, color, ctx) => {
					if (node.parent === "root") {
						circleRadius = rootRadius
					} else if (node.level === 1) {
						circleRadius = level1Radius
					} else if (node.level === 2) {
						circleRadius = level2Radius
					} else if (node.level > 2) {
						circleRadius = level3Radius
					}
					ctx.fillStyle = color
					ctx.beginPath()
					ctx.arc(node.x, node.y, circleRadius + 1.75, 0, 2 * Math.PI, false)
					ctx.fill()
				})
				.width(window.innerWidth)
      			.height(window.innerHeight);

			//set 100% width to canvas
			const canvas = document.querySelector("canvas")
			canvas.style.width = "100%"
			canvas.style.height = "100%"

			window.addEventListener("resize", () => {
				canvas.style.width = "100%"
				canvas.style.height = "100%"
			})

			setTimeout(() => {
				this.$store.commit('client/setHidePageLoader', true);
			}, 4000)
		},
		
	},
	async mounted() {
		this.$store.commit('client/setHidePageLoader', false);
		main = document.getElementById("main")
		sidebarWrapper = document.getElementById("sidebarWrapper")
		nodeForm = document.getElementById("add-node-form")
		nodeOverview = document.getElementById("node-overview")
		range = document.getElementById("graphZoom")
		// If there is a nodeId query parameter in the URL set it as the currentNode
		if (this.$route.query?.node) {
			if (this.getClientContentTree.nodeIds.find((nodeId) => nodeId === this.$route.query?.node)) {
				this.selectNode(this.$route.query.node)
			}
		}

		setTimeout(() => {
			// If the no node in the tree is selected
			if (!this.getClientContentTree.nodeIds.find((nodeId) => nodeId === this.getCurrentNode.id)) {
				// Fit to screen
				if (this.getClientContentTree.nodeIds.length > 8) {
					this.fitToScreen()
				}
				
			} else {
				let currentNodeLevel = this.getCurrentNode.level
				if (currentNodeLevel > 1) {
					this.Graph.zoom(20, 4000)
				} else {
					this.Graph.zoom(12, 4000)
				}
			}
		}, 750)

		this.renderContentTree();

	},
}
</script>

<style lang="scss" scoped>
@import "./src/assets/scss/views/contentTree.scss";
</style>
